.loading__text {
  padding: 0 20px;
  line-height: 1.3;
}
.loading__text h3,
.loading__text h4,
.loading__text p {
  margin-bottom: 20px;
}
.loading__text .loading__option {
  margin-bottom: 15px;
}
.loading__text p:last-child {
  margin-bottom: 0;
}
.loading__text h4 {
  text-decoration: underline;
}

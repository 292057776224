html,
body {
  margin: 0;
  padding: 0;
  height: 100%,
}

/* TYPOGRAPHY */

ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
h1,
h2,
h3 {
  margin: 0;
}
h2 {
  font-size: 18px;
}

/* APP SPECIFIC */

.app {
  padding-top: 120px; /* compensate for header */
  padding-bottom: 42px; /* compensate for footer */
}
@media (max-width: 320px) {
  .app {
    padding-top: 196px; /* compensate for header */
  }
}
.countries {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
}
.country {
  margin-bottom: 10px;
  padding: 5px;
  background-color: #efefef;
}
.heading--country {
  font-size: 22px;
  margin-bottom: 5px;
}
.stats {
  justify-content: space-evenly;
}
.stats {
  margin-bottom: 5px;
}
.stat {
  padding: 5px 8px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
}
.percentages {
  height: 12px;
}
.total {
  margin-top: 5px;
  text-align: center;
  padding: 5px 0;
  background-color: #dcdcdc;
  color: black;
}
.stat--cases,
.percentage--cases,
.legend--cases .legend__color {
  background-color: #1978D4;
  color: white;
}
.stat--sick,
.percentage--sick,
.legend--sick .legend__color {
  background-color: #f7ca18;
  color: black;
}
.stat--cures,
.percentage--cures,
.legend--cures .legend__color {
  background-color: #007e00;
  color: white;
}
.stat--deaths,
.percentage--deaths,
.legend--deaths .legend__color {
  background-color: #6100b5;
  color: white;
}
.search--country,
.dropdown--sortby {
  margin: 15px 10px 10px;
}
.dropdown--sortby .dropdown__label {
  display: block;
  margin-bottom: 8px;
}

/* HELPERS */

.display-flex {
  display: flex;
}
.flex-direction-column {
  flex-direction: column;
}
.align-center {
  text-align: center;
}
.centered {
  margin: 0 auto;
}

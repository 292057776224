.search label {
  position: relative;
  color: black;
  font-size: 16px;
}
.search__label {
  display: block;
  margin-bottom: 8px;
}
.search__input {
  border: 1px solid black;
  border-radius: 0;
  padding: 10px 35px;
  font-size: 16px;
  color: black;
  line-height: 1.2;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.search__icon-search {
  position: absolute;
  left: 10px;
  bottom: 0;
}
.search__icon-close {
  position: absolute;
  right: 10px;
  bottom: 0;
  cursor: pointer;
}

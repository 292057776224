.icon {
  margin: auto;
  display: inline-block;
}
.icon__address { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='address' data-name='address' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eadress%3C/title%3E%3Cpath fill='%23333' d='M40,0A32.07,32.07,0,0,0,8,32.07a31.24,31.24,0,0,0,.57,6c0,.12.08.47.22,1.07a31.18,31.18,0,0,0,2.14,6.32c3.44,8.14,11,20.63,27.65,34a2.27,2.27,0,0,0,2.84,0C58,66.11,65.63,53.62,69.07,45.48a30.77,30.77,0,0,0,2.14-6.32c.14-.6.2-.95.22-1.07a32.91,32.91,0,0,0,.57-6A32.12,32.12,0,0,0,40,0ZM67,37.3s0,.07,0,.1-.07.34-.15.72v0a26.86,26.86,0,0,1-1.86,5.46s0,.05,0,.07C61.76,51.12,54.9,62.46,40,74.82,25.06,62.46,18.2,51.12,15.08,43.69c0,0,0,0,0-.07a28.32,28.32,0,0,1-1.85-5.46v0A6,6,0,0,1,13,37.4s0-.07,0-.12a27,27,0,0,1-.5-5.23A27.47,27.47,0,1,1,67,37.3Z' /%3E%3Cpath fill='%23333' d='M40,12.06A20.36,20.36,0,1,0,60.29,32.42,20.36,20.36,0,0,0,40,12.06Zm0,36.19A15.83,15.83,0,1,1,55.77,32.42,15.83,15.83,0,0,1,40,48.25Z' /%3E%3C/svg%3E") }
.icon__address--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='addressWhite' data-name='addressWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eadress_white%3C/title%3E%3Cpath fill='%23fff' d='M40,0A32.07,32.07,0,0,0,8,32.07a31.24,31.24,0,0,0,.57,6c0,.12.08.47.22,1.07a31.18,31.18,0,0,0,2.14,6.32c3.44,8.14,11,20.63,27.65,34a2.27,2.27,0,0,0,2.84,0C58,66.11,65.63,53.62,69.07,45.48a30.77,30.77,0,0,0,2.14-6.32c.14-.6.2-.95.22-1.07a32.91,32.91,0,0,0,.57-6A32.12,32.12,0,0,0,40,0ZM67,37.3s0,.07,0,.1-.07.34-.15.72v0a26.86,26.86,0,0,1-1.86,5.46s0,.05,0,.07C61.76,51.12,54.9,62.46,40,74.82,25.06,62.46,18.2,51.12,15.08,43.69c0,0,0,0,0-.07a28.32,28.32,0,0,1-1.85-5.46v0A6,6,0,0,1,13,37.4s0-.07,0-.12a27,27,0,0,1-.5-5.23A27.47,27.47,0,1,1,67,37.3Z' /%3E%3Cpath fill='%23fff' d='M40,12.06A20.36,20.36,0,1,0,60.29,32.42,20.36,20.36,0,0,0,40,12.06Zm0,36.19A15.83,15.83,0,1,1,55.77,32.42,15.83,15.83,0,0,1,40,48.25Z' /%3E%3C/svg%3E") }
.icon__address--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='addressRed' data-name='addressRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eadress_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M40,0A32.07,32.07,0,0,0,8,32.07a31.24,31.24,0,0,0,.57,6c0,.12.08.47.22,1.07a31.18,31.18,0,0,0,2.14,6.32c3.44,8.14,11,20.63,27.65,34a2.27,2.27,0,0,0,2.84,0C58,66.11,65.63,53.62,69.07,45.48a30.77,30.77,0,0,0,2.14-6.32c.14-.6.2-.95.22-1.07a32.91,32.91,0,0,0,.57-6A32.12,32.12,0,0,0,40,0ZM67,37.3s0,.07,0,.1-.07.34-.15.72v0a26.86,26.86,0,0,1-1.86,5.46s0,.05,0,.07C61.76,51.12,54.9,62.46,40,74.82,25.06,62.46,18.2,51.12,15.08,43.69c0,0,0,0,0-.07a28.32,28.32,0,0,1-1.85-5.46v0A6,6,0,0,1,13,37.4s0-.07,0-.12a27,27,0,0,1-.5-5.23A27.47,27.47,0,1,1,67,37.3Z' /%3E%3Cpath fill='%23d42b1e' d='M40,12.06A20.36,20.36,0,1,0,60.29,32.42,20.36,20.36,0,0,0,40,12.06Zm0,36.19A15.83,15.83,0,1,1,55.77,32.42,15.83,15.83,0,0,1,40,48.25Z' /%3E%3C/svg%3E") }
.icon__arrowDown { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='arrowDown' data-name='arrowDown' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Earrow_down%3C/title%3E%3Cpath fill='%23333' d='M40,80a1.94,1.94,0,0,1-1.43-.64l-24-26.18A2.16,2.16,0,0,1,14,51.73a2.2,2.2,0,0,1,.6-1.51,1.92,1.92,0,0,1,2.81.06L38,72.74V2.09a2,2,0,1,1,4,0V72.74L62.59,50.28a1.92,1.92,0,0,1,2.81-.06,2.18,2.18,0,0,1,0,3l-24,26.18A1.94,1.94,0,0,1,40,80Z' /%3E%3C/svg%3E") }
.icon__arrowDown--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='arrowDownWhite' data-name='arrowDownWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Earrow_down_white%3C/title%3E%3Cpath fill='%23fff' d='M40,80a1.94,1.94,0,0,1-1.43-.64l-24-26.18A2.16,2.16,0,0,1,14,51.73a2.2,2.2,0,0,1,.6-1.51,1.92,1.92,0,0,1,2.81.06L38,72.74V2.09a2,2,0,1,1,4,0V72.74L62.59,50.28a1.92,1.92,0,0,1,2.81-.06,2.18,2.18,0,0,1,0,3l-24,26.18A1.94,1.94,0,0,1,40,80Z' /%3E%3C/svg%3E") }
.icon__arrowDown--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='arrowDownRed' data-name='arrowDownRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Earrow_down_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M40,80a1.94,1.94,0,0,1-1.43-.64l-24-26.18A2.16,2.16,0,0,1,14,51.73a2.2,2.2,0,0,1,.6-1.51,1.92,1.92,0,0,1,2.81.06L38,72.74V2.09a2,2,0,1,1,4,0V72.74L62.59,50.28a1.92,1.92,0,0,1,2.81-.06,2.18,2.18,0,0,1,0,3l-24,26.18A1.94,1.94,0,0,1,40,80Z' /%3E%3C/svg%3E") }
.icon__arrow { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='arrow' data-name='arrow' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Earrow%3C/title%3E%3Cpath fill='%23333' d='M80,39.93a1.94,1.94,0,0,1-.64,1.43l-26.18,24a2.19,2.19,0,0,1-3,0,1.92,1.92,0,0,1,.06-2.81L72.74,41.91H2.09a2,2,0,1,1,0-4H72.74L50.28,17.34a1.91,1.91,0,0,1-.06-2.8,2.17,2.17,0,0,1,3,0l26.18,24A2,2,0,0,1,80,39.93Z' /%3E%3C/svg%3E") }
.icon__arrow--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='arrowWhite' data-name='arrowWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Earrow_white%3C/title%3E%3Cpath fill='%23fff' d='M80,39.93a1.94,1.94,0,0,1-.64,1.43l-26.18,24a2.19,2.19,0,0,1-3,0,1.92,1.92,0,0,1,.06-2.81L72.74,41.91H2.09a2,2,0,1,1,0-4H72.74L50.28,17.34a1.91,1.91,0,0,1-.06-2.8,2.17,2.17,0,0,1,3,0l26.18,24A2,2,0,0,1,80,39.93Z' /%3E%3C/svg%3E") }
.icon__arrow--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='arrowRed' data-name='arrowRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Earrow_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M80,39.93a1.94,1.94,0,0,1-.64,1.43l-26.18,24a2.19,2.19,0,0,1-3,0,1.92,1.92,0,0,1,.06-2.81L72.74,41.91H2.09a2,2,0,1,1,0-4H72.74L50.28,17.34a1.91,1.91,0,0,1-.06-2.8,2.17,2.17,0,0,1,3,0l26.18,24A2,2,0,0,1,80,39.93Z' /%3E%3C/svg%3E") }
.icon__cal { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='cal' data-name='cal' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Ecal%3C/title%3E%3Cpath fill='%23333' d='M76,12V76H4V12H76m0-4H4a4,4,0,0,0-4,4V76a4,4,0,0,0,4,4H76a4,4,0,0,0,4-4V12a4,4,0,0,0-4-4Z' /%3E%3Crect fill='%23333' x='16' y='30' width='12' height='8' rx='2' /%3E%3Crect fill='%23333' x='34' y='30' width='12' height='8' rx='2' /%3E%3Crect fill='%23333' x='52' y='30' width='12' height='8' rx='2' /%3E%3Crect fill='%23333' x='16' y='42' width='12' height='8' rx='2' /%3E%3Crect fill='%23333' x='34' y='42' width='12' height='8' rx='2' /%3E%3Crect fill='%23333' x='52' y='42' width='12' height='8' rx='2' /%3E%3Crect fill='%23333' x='16' y='54' width='12' height='8' rx='2' /%3E%3Crect fill='%23333' x='34' y='54' width='12' height='8' rx='2' /%3E%3Crect fill='%23333' x='52' y='54' width='12' height='8' rx='2' /%3E%3Crect fill='%23333' x='58' width='4' height='20' rx='2' /%3E%3Crect fill='%23333' x='38' width='4' height='20' rx='2' /%3E%3Crect fill='%23333' x='18' width='4' height='20' rx='2' /%3E%3C/svg%3E") }
.icon__cal--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='calWhite' data-name='calWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Ecal_white%3C/title%3E%3Cpath fill='%23fff' d='M76,12V76H4V12H76m0-4H4a4,4,0,0,0-4,4V76a4,4,0,0,0,4,4H76a4,4,0,0,0,4-4V12a4,4,0,0,0-4-4Z' /%3E%3Crect fill='%23fff' x='16' y='30' width='12' height='8' rx='2' /%3E%3Crect fill='%23fff' x='34' y='30' width='12' height='8' rx='2' /%3E%3Crect fill='%23fff' x='52' y='30' width='12' height='8' rx='2' /%3E%3Crect fill='%23fff' x='16' y='42' width='12' height='8' rx='2' /%3E%3Crect fill='%23fff' x='34' y='42' width='12' height='8' rx='2' /%3E%3Crect fill='%23fff' x='52' y='42' width='12' height='8' rx='2' /%3E%3Crect fill='%23fff' x='16' y='54' width='12' height='8' rx='2' /%3E%3Crect fill='%23fff' x='34' y='54' width='12' height='8' rx='2' /%3E%3Crect fill='%23fff' x='52' y='54' width='12' height='8' rx='2' /%3E%3Crect fill='%23fff' x='58' width='4' height='20' rx='2' /%3E%3Crect fill='%23fff' x='38' width='4' height='20' rx='2' /%3E%3Crect fill='%23fff' x='18' width='4' height='20' rx='2' /%3E%3C/svg%3E") }
.icon__cal--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='calRed' data-name='calRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Ecal_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M76,12V76H4V12H76m0-4H4a4,4,0,0,0-4,4V76a4,4,0,0,0,4,4H76a4,4,0,0,0,4-4V12a4,4,0,0,0-4-4Z' /%3E%3Crect fill='%23d42b1e' x='16' y='30' width='12' height='8' rx='2' /%3E%3Crect fill='%23d42b1e' x='34' y='30' width='12' height='8' rx='2' /%3E%3Crect fill='%23d42b1e' x='52' y='30' width='12' height='8' rx='2' /%3E%3Crect fill='%23d42b1e' x='16' y='42' width='12' height='8' rx='2' /%3E%3Crect fill='%23d42b1e' x='34' y='42' width='12' height='8' rx='2' /%3E%3Crect fill='%23d42b1e' x='52' y='42' width='12' height='8' rx='2' /%3E%3Crect fill='%23d42b1e' x='16' y='54' width='12' height='8' rx='2' /%3E%3Crect fill='%23d42b1e' x='34' y='54' width='12' height='8' rx='2' /%3E%3Crect fill='%23d42b1e' x='52' y='54' width='12' height='8' rx='2' /%3E%3Crect fill='%23d42b1e' x='58' width='4' height='20' rx='2' /%3E%3Crect fill='%23d42b1e' x='38' width='4' height='20' rx='2' /%3E%3Crect fill='%23d42b1e' x='18' width='4' height='20' rx='2' /%3E%3C/svg%3E") }
.icon__close { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='close' data-name='close' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eclose%3C/title%3E%3Cpath fill='%23333' d='M2.55,80a2.55,2.55,0,0,1-1.8-4.35L75.54.75a2.56,2.56,0,0,1,3.62,3.61L4.36,79.25A2.54,2.54,0,0,1,2.55,80Z' /%3E%3Cpath fill='%23333' d='M77.45,80a2.54,2.54,0,0,1-1.8-.75L.75,4.38A2.57,2.57,0,0,1,.74.76a2.54,2.54,0,0,1,3.61,0l74.9,74.87a2.57,2.57,0,0,1,0,3.62A2.53,2.53,0,0,1,77.45,80Z' /%3E%3C/svg%3E") }
.icon__close--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='closeWhite' data-name='closeWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eclose_white%3C/title%3E%3Cpath fill='%23fff' d='M2.55,80a2.55,2.55,0,0,1-1.8-4.35L75.54.75a2.56,2.56,0,0,1,3.62,3.61L4.36,79.25A2.54,2.54,0,0,1,2.55,80Z' /%3E%3Cpath fill='%23fff' d='M77.45,80a2.54,2.54,0,0,1-1.8-.75L.75,4.38A2.57,2.57,0,0,1,.74.76a2.54,2.54,0,0,1,3.61,0l74.9,74.87a2.57,2.57,0,0,1,0,3.62A2.53,2.53,0,0,1,77.45,80Z' /%3E%3C/svg%3E") }
.icon__close--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='closeRed' data-name='closeRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eclose_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M2.55,80a2.55,2.55,0,0,1-1.8-4.35L75.54.75a2.56,2.56,0,0,1,3.62,3.61L4.36,79.25A2.54,2.54,0,0,1,2.55,80Z' /%3E%3Cpath fill='%23d42b1e' d='M77.45,80a2.54,2.54,0,0,1-1.8-.75L.75,4.38A2.57,2.57,0,0,1,.74.76a2.54,2.54,0,0,1,3.61,0l74.9,74.87a2.57,2.57,0,0,1,0,3.62A2.53,2.53,0,0,1,77.45,80Z' /%3E%3C/svg%3E") }
.icon__download { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='download' data-name='download' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Edownload%3C/title%3E%3Cpath fill='%23333' d='M77.46,80H2.28A2.19,2.19,0,0,1,0,77.91v-16a1.78,1.78,0,0,1,1.87-2,1.88,1.88,0,0,1,2,2v13.9h72V63.93a1.89,1.89,0,0,1,2-2A2,2,0,0,1,80,64V78A2.45,2.45,0,0,1,77.46,80Z' /%3E%3Cpath fill='%23333' d='M71.87,32.1a2.7,2.7,0,0,0-2.11-2.17l-13.89,0V5a5.07,5.07,0,0,0-5.09-5H28.71a5.07,5.07,0,0,0-5.09,5V29.93H10a2,2,0,0,0-2.1,2,4.4,4.4,0,0,0,.71,2.28L38.4,67a2.11,2.11,0,0,0,3.12,0l29.8-32.62A2.84,2.84,0,0,0,71.87,32.1ZM40,62.47,14.74,33.9l10.14,0h.84a2.1,2.1,0,0,0,2.11-2.07l0-25.93a1.78,1.78,0,0,1,2-2h20c2.06,0,2,1.41,2,2,0,2,0,24,0,26a2,2,0,0,0,2,1.93l1.08.07L65,34Z' /%3E%3C/svg%3E") }
.icon__download--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='downloadWhite' data-name='downloadWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Edownload_white%3C/title%3E%3Cpath fill='%23fff' d='M77.46,80H2.28A2.19,2.19,0,0,1,0,77.91v-16a1.78,1.78,0,0,1,1.87-2,1.88,1.88,0,0,1,2,2v13.9h72V63.93a1.89,1.89,0,0,1,2-2A2,2,0,0,1,80,64V78A2.45,2.45,0,0,1,77.46,80Z' /%3E%3Cpath fill='%23fff' d='M71.87,32.1a2.7,2.7,0,0,0-2.11-2.17l-13.89,0V5a5.07,5.07,0,0,0-5.09-5H28.71a5.07,5.07,0,0,0-5.09,5V29.93H10a2,2,0,0,0-2.1,2,4.4,4.4,0,0,0,.71,2.28L38.4,67a2.11,2.11,0,0,0,3.12,0l29.8-32.62A2.84,2.84,0,0,0,71.87,32.1ZM40,62.47,14.74,33.9l10.14,0h.84a2.1,2.1,0,0,0,2.11-2.07l0-25.93a1.78,1.78,0,0,1,2-2h20c2.06,0,2,1.41,2,2,0,2,0,24,0,26a2,2,0,0,0,2,1.93l1.08.07L65,34Z' /%3E%3C/svg%3E") }
.icon__download--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='downloadRed' data-name='downloadRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Edownload_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M77.46,80H2.28A2.19,2.19,0,0,1,0,77.91v-16a1.78,1.78,0,0,1,1.87-2,1.88,1.88,0,0,1,2,2v13.9h72V63.93a1.89,1.89,0,0,1,2-2A2,2,0,0,1,80,64V78A2.45,2.45,0,0,1,77.46,80Z' /%3E%3Cpath fill='%23d42b1e' d='M71.87,32.1a2.7,2.7,0,0,0-2.11-2.17l-13.89,0V5a5.07,5.07,0,0,0-5.09-5H28.71a5.07,5.07,0,0,0-5.09,5V29.93H10a2,2,0,0,0-2.1,2,4.4,4.4,0,0,0,.71,2.28L38.4,67a2.11,2.11,0,0,0,3.12,0l29.8-32.62A2.84,2.84,0,0,0,71.87,32.1ZM40,62.47,14.74,33.9l10.14,0h.84a2.1,2.1,0,0,0,2.11-2.07l0-25.93a1.78,1.78,0,0,1,2-2h20c2.06,0,2,1.41,2,2,0,2,0,24,0,26a2,2,0,0,0,2,1.93l1.08.07L65,34Z' /%3E%3C/svg%3E") }
.icon__edit { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='edit' data-name='edit' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eedit%3C/title%3E%3Cpath fill='%23333' d='M48.92,4.14l-.16.16.17-.15Z' /%3E%3Cpath fill='none' d='M73.24,17a13,13,0,0,1-3.06,8.4L51.78,7A13.05,13.05,0,0,1,73.24,17Z' /%3E%3Cpolygon fill='none' points='56.73 17.6 15.83 58.5 8.02 50.69 43.17 15.54 48.92 9.79 56.73 17.6' /%3E%3Cpolygon fill='none' points='67.34 28.21 61.54 34.01 26.44 69.11 18.66 61.33 59.56 20.43 67.34 28.21' /%3E%3Cpath fill='%23333' d='M62.34,0A17.48,17.48,0,0,0,50.72,4.39a1.37,1.37,0,0,0-.18.16l-.07.06-1.2,1.21-.06.07-41.79,42-.53.53a15.36,15.36,0,0,0-3.08,4.63c-.1.25-.18.5-.26.75a7.77,7.77,0,0,0-.21.93L3,57c0,.31-.08.6-.12.85l-.06.35c0,.22-.06.42-.08.59s0,.3-.06.41l-.06.42L0,77.63A2.09,2.09,0,0,0,2.07,80h16a2.07,2.07,0,0,0,0-4.14H4.46L7.14,57.43,27.45,77.87,73.58,31.46A17.78,17.78,0,0,0,62.34,0ZM8.4,52.81,44.76,16.24l6-6,8.07,8.12L16.48,60.94ZM63.76,35.46,27.45,72l-8-8.1L61.71,21.33l8.05,8.09Zm8.94-9-19-19.14a13.4,13.4,0,0,1,8.68-3.2A13.62,13.62,0,0,1,72.7,26.5Z' /%3E%3C/svg%3E") }
.icon__edit--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='editWhite' data-name='editWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eedit_white%3C/title%3E%3Cpath fill='%23fff' d='M48.92,4.14l-.16.16.17-.15Z' /%3E%3Cpath fill='none' d='M73.24,17a13,13,0,0,1-3.06,8.4L51.78,7A13.05,13.05,0,0,1,73.24,17Z' /%3E%3Cpolygon fill='none' points='56.73 17.6 15.83 58.5 8.02 50.69 43.17 15.54 48.92 9.79 56.73 17.6' /%3E%3Cpolygon fill='none' points='67.34 28.21 61.54 34.01 26.44 69.11 18.66 61.33 59.56 20.43 67.34 28.21' /%3E%3Cpath fill='%23fff' d='M62.34,0A17.48,17.48,0,0,0,50.72,4.39a1.37,1.37,0,0,0-.18.16l-.07.06-1.2,1.21-.06.07-41.79,42-.53.53a15.36,15.36,0,0,0-3.08,4.63c-.1.25-.18.5-.26.75a7.77,7.77,0,0,0-.21.93L3,57c0,.31-.08.6-.12.85l-.06.35c0,.22-.06.42-.08.59s0,.3-.06.41l-.06.42L0,77.63A2.09,2.09,0,0,0,2.07,80h16a2.07,2.07,0,0,0,0-4.14H4.46L7.14,57.43,27.45,77.87,73.58,31.46A17.78,17.78,0,0,0,62.34,0ZM8.4,52.81,44.76,16.24l6-6,8.07,8.12L16.48,60.94ZM63.76,35.46,27.45,72l-8-8.1L61.71,21.33l8.05,8.09Zm8.94-9-19-19.14a13.4,13.4,0,0,1,8.68-3.2A13.62,13.62,0,0,1,72.7,26.5Z' /%3E%3C/svg%3E") }
.icon__edit--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='editRed' data-name='editRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eedit_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M48.92,4.14l-.16.16.17-.15Z' /%3E%3Cpath fill='none' d='M73.24,17a13,13,0,0,1-3.06,8.4L51.78,7A13.05,13.05,0,0,1,73.24,17Z' /%3E%3Cpolygon fill='none' points='56.73 17.6 15.83 58.5 8.02 50.69 43.17 15.54 48.92 9.79 56.73 17.6' /%3E%3Cpolygon fill='none' points='67.34 28.21 61.54 34.01 26.44 69.11 18.66 61.33 59.56 20.43 67.34 28.21' /%3E%3Cpath fill='%23d42b1e' d='M62.34,0A17.48,17.48,0,0,0,50.72,4.39a1.37,1.37,0,0,0-.18.16l-.07.06-1.2,1.21-.06.07-41.79,42-.53.53a15.36,15.36,0,0,0-3.08,4.63c-.1.25-.18.5-.26.75a7.77,7.77,0,0,0-.21.93L3,57c0,.31-.08.6-.12.85l-.06.35c0,.22-.06.42-.08.59s0,.3-.06.41l-.06.42L0,77.63A2.09,2.09,0,0,0,2.07,80h16a2.07,2.07,0,0,0,0-4.14H4.46L7.14,57.43,27.45,77.87,73.58,31.46A17.78,17.78,0,0,0,62.34,0ZM8.4,52.81,44.76,16.24l6-6,8.07,8.12L16.48,60.94ZM63.76,35.46,27.45,72l-8-8.1L61.71,21.33l8.05,8.09Zm8.94-9-19-19.14a13.4,13.4,0,0,1,8.68-3.2A13.62,13.62,0,0,1,72.7,26.5Z' /%3E%3C/svg%3E") }
.icon__filter { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='filter' data-name='filter' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Efilter%3C/title%3E%3Cpath fill='%23333' d='M78,15.92H26.05a2,2,0,0,1,0-4H78a2,2,0,0,1,0,4Z' /%3E%3Cpath fill='%23333' d='M14,4A10,10,0,1,1,4,14,10,10,0,0,1,14,4m0-4A14,14,0,1,0,28,14,14,14,0,0,0,14,0Z' /%3E%3Cpath fill='%23333' d='M78,68H26.05a2,2,0,1,1,0-4H78a2,2,0,0,1,0,4Z' /%3E%3Cpath fill='%23333' d='M14,56A10,10,0,1,1,4,66,10,10,0,0,1,14,56m0-4A14,14,0,1,0,28,66,14,14,0,0,0,14,52Z' /%3E%3Cpath fill='%23333' d='M54,41.89H2a2,2,0,0,1,0-4H54a2,2,0,0,1,0,4Z' /%3E%3Cpath fill='%23333' d='M66,29.84a10,10,0,1,1-10,10,10,10,0,0,1,10-10m0-4a14,14,0,1,0,14,14,14,14,0,0,0-14-14Z' /%3E%3C/svg%3E") }
.icon__filter--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='filterWhite' data-name='filterWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Efilter_white%3C/title%3E%3Cpath fill='%23fff' d='M78,15.92H26.05a2,2,0,0,1,0-4H78a2,2,0,0,1,0,4Z' /%3E%3Cpath fill='%23fff' d='M14,4A10,10,0,1,1,4,14,10,10,0,0,1,14,4m0-4A14,14,0,1,0,28,14,14,14,0,0,0,14,0Z' /%3E%3Cpath fill='%23fff' d='M78,68H26.05a2,2,0,1,1,0-4H78a2,2,0,0,1,0,4Z' /%3E%3Cpath fill='%23fff' d='M14,56A10,10,0,1,1,4,66,10,10,0,0,1,14,56m0-4A14,14,0,1,0,28,66,14,14,0,0,0,14,52Z' /%3E%3Cpath fill='%23fff' d='M54,41.89H2a2,2,0,0,1,0-4H54a2,2,0,0,1,0,4Z' /%3E%3Cpath fill='%23fff' d='M66,29.84a10,10,0,1,1-10,10,10,10,0,0,1,10-10m0-4a14,14,0,1,0,14,14,14,14,0,0,0-14-14Z' /%3E%3C/svg%3E") }
.icon__filter--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='filterRed' data-name='filterRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Efilter_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M78,15.92H26.05a2,2,0,0,1,0-4H78a2,2,0,0,1,0,4Z' /%3E%3Cpath fill='%23d42b1e' d='M14,4A10,10,0,1,1,4,14,10,10,0,0,1,14,4m0-4A14,14,0,1,0,28,14,14,14,0,0,0,14,0Z' /%3E%3Cpath fill='%23d42b1e' d='M78,68H26.05a2,2,0,1,1,0-4H78a2,2,0,0,1,0,4Z' /%3E%3Cpath fill='%23d42b1e' d='M14,56A10,10,0,1,1,4,66,10,10,0,0,1,14,56m0-4A14,14,0,1,0,28,66,14,14,0,0,0,14,52Z' /%3E%3Cpath fill='%23d42b1e' d='M54,41.89H2a2,2,0,0,1,0-4H54a2,2,0,0,1,0,4Z' /%3E%3Cpath fill='%23d42b1e' d='M66,29.84a10,10,0,1,1-10,10,10,10,0,0,1,10-10m0-4a14,14,0,1,0,14,14,14,14,0,0,0-14-14Z' /%3E%3C/svg%3E") }
.icon__home { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='home' data-name='home' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Ehome%3C/title%3E%3Cpath fill='%23333' d='M66,41.62a2,2,0,0,0-2,2.28V75.81H48v-12a1.94,1.94,0,0,0-2-2H34.1a1.89,1.89,0,0,0-2.12,2v12H16v-32a1.84,1.84,0,0,0-2-2,1.9,1.9,0,0,0-2,2v34A2,2,0,0,0,14,80H34a2,2,0,0,0,2-2.18v-12h8v12A2,2,0,0,0,46,80H66a2,2,0,0,0,2-2.18V44A2,2,0,0,0,66,41.62Z' /%3E%3Cpath fill='%23333' d='M79.33,38.69,61.17,20.43V8a2.25,2.25,0,0,0-2.38-2.28A2.21,2.21,0,0,0,56.61,8v7.88L41.57.73A2.41,2.41,0,0,0,40,0a2.66,2.66,0,0,0-1.66.73L.7,38.38a2.19,2.19,0,0,0,0,3.21,2.19,2.19,0,0,0,3.22,0L40,5.5l36.1,36.41a2.21,2.21,0,0,0,1.66.62,2.58,2.58,0,0,0,1.66-.62A2.4,2.4,0,0,0,79.33,38.69Z' /%3E%3C/svg%3E") }
.icon__home--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='homeWhite' data-name='homeWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Ehome_white%3C/title%3E%3Cpath fill='%23fff' d='M66,41.62a2,2,0,0,0-2,2.28V75.81H48v-12a1.94,1.94,0,0,0-2-2H34.1a1.89,1.89,0,0,0-2.12,2v12H16v-32a1.84,1.84,0,0,0-2-2,1.9,1.9,0,0,0-2,2v34A2,2,0,0,0,14,80H34a2,2,0,0,0,2-2.18v-12h8v12A2,2,0,0,0,46,80H66a2,2,0,0,0,2-2.18V44A2,2,0,0,0,66,41.62Z' /%3E%3Cpath fill='%23fff' d='M79.33,38.69,61.17,20.43V8a2.25,2.25,0,0,0-2.38-2.28A2.21,2.21,0,0,0,56.61,8v7.88L41.57.73A2.41,2.41,0,0,0,40,0a2.66,2.66,0,0,0-1.66.73L.7,38.38a2.19,2.19,0,0,0,0,3.21,2.19,2.19,0,0,0,3.22,0L40,5.5l36.1,36.41a2.21,2.21,0,0,0,1.66.62,2.58,2.58,0,0,0,1.66-.62A2.4,2.4,0,0,0,79.33,38.69Z' /%3E%3C/svg%3E") }
.icon__home--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='homeRed' data-name='homeRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Ehome_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M66,41.62a2,2,0,0,0-2,2.28V75.81H48v-12a1.94,1.94,0,0,0-2-2H34.1a1.89,1.89,0,0,0-2.12,2v12H16v-32a1.84,1.84,0,0,0-2-2,1.9,1.9,0,0,0-2,2v34A2,2,0,0,0,14,80H34a2,2,0,0,0,2-2.18v-12h8v12A2,2,0,0,0,46,80H66a2,2,0,0,0,2-2.18V44A2,2,0,0,0,66,41.62Z' /%3E%3Cpath fill='%23d42b1e' d='M79.33,38.69,61.17,20.43V8a2.25,2.25,0,0,0-2.38-2.28A2.21,2.21,0,0,0,56.61,8v7.88L41.57.73A2.41,2.41,0,0,0,40,0a2.66,2.66,0,0,0-1.66.73L.7,38.38a2.19,2.19,0,0,0,0,3.21,2.19,2.19,0,0,0,3.22,0L40,5.5l36.1,36.41a2.21,2.21,0,0,0,1.66.62,2.58,2.58,0,0,0,1.66-.62A2.4,2.4,0,0,0,79.33,38.69Z' /%3E%3C/svg%3E") }
.icon__logout { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='logout' data-name='logout' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Elogout%3C/title%3E%3Cpath fill='%23333' d='M22.32.92a5.2,5.2,0,0,0-4.8-.53L5.11,5.4C1.46,6.75,0,8.73,0,12.38V67.63c0,3.65,1.46,5.63,5.11,7l12.51,5a6.75,6.75,0,0,0,2,.42c2.93,0,6.4-2.4,6.4-5.74V5.81C26,3.73,23.78,2,22.32.92ZM22,74.2a2,2,0,0,1-2,2,7.65,7.65,0,0,1-2-.28L6.25,71.18C4,70.15,4,69.72,4,67.63V12.38C4,10.3,4,9.67,6.36,8.84L17.8,4.58A6.71,6.71,0,0,1,20,4.15a2.08,2.08,0,0,1,2,2Z' /%3E%3Cpath fill='%23333' d='M18,36.15v8a2,2,0,0,1-1.88,2,2.18,2.18,0,0,1-2.1-2v-8a2.1,2.1,0,0,1,2-2A2.07,2.07,0,0,1,18,36.15Z' /%3E%3Cpath fill='%23333' d='M55.42,12.9V32.08a2,2,0,0,1-4,0V13a2.87,2.87,0,0,0-3.19-3H29.42a2.08,2.08,0,0,1-2-2,2.09,2.09,0,0,1,2-2H48.33C53.42,6.07,55.42,8.94,55.42,12.9Z' /%3E%3Cpath fill='%23333' d='M55.42,47.93v19.7c0,4.44-2,6.44-7.09,6.44H29.42a2,2,0,0,1,0-4H48.33c1.88,0,3.09-.67,3.09-2.54V47.93a2,2,0,0,1,2-1.88A2.05,2.05,0,0,1,55.42,47.93Z' /%3E%3Cpath fill='%23333' d='M80,40a1.84,1.84,0,0,1-.1.73,2.27,2.27,0,0,1-.42.62l-13.25,13A1.61,1.61,0,0,1,65,55a1.73,1.73,0,0,1-1.25-.52,1.83,1.83,0,0,1,0-2.61l10-9.85H31.3a2,2,0,0,1,0-4H73.74l-10-9.92a1.84,1.84,0,1,1,2.61-2.6L79.48,38.65a2.31,2.31,0,0,1,.42.63C79.9,39.49,80,39.8,80,40Z' /%3E%3C/svg%3E") }
.icon__logout--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='logoutWhite' data-name='logoutWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Elogout_white%3C/title%3E%3Cpath fill='%23fff' d='M22.32.92a5.2,5.2,0,0,0-4.8-.53L5.11,5.4C1.46,6.75,0,8.73,0,12.38V67.63c0,3.65,1.46,5.63,5.11,7l12.51,5a6.75,6.75,0,0,0,2,.42c2.93,0,6.4-2.4,6.4-5.74V5.81C26,3.73,23.78,2,22.32.92ZM22,74.2a2,2,0,0,1-2,2,7.65,7.65,0,0,1-2-.28L6.25,71.18C4,70.15,4,69.72,4,67.63V12.38C4,10.3,4,9.67,6.36,8.84L17.8,4.58A6.71,6.71,0,0,1,20,4.15a2.08,2.08,0,0,1,2,2Z' /%3E%3Cpath fill='%23fff' d='M18,36.15v8a2,2,0,0,1-1.88,2,2.18,2.18,0,0,1-2.1-2v-8a2.1,2.1,0,0,1,2-2A2.07,2.07,0,0,1,18,36.15Z' /%3E%3Cpath fill='%23fff' d='M55.42,12.9V32.08a2,2,0,0,1-4,0V13a2.87,2.87,0,0,0-3.19-3H29.42a2.08,2.08,0,0,1-2-2,2.09,2.09,0,0,1,2-2H48.33C53.42,6.07,55.42,8.94,55.42,12.9Z' /%3E%3Cpath fill='%23fff' d='M55.42,47.93v19.7c0,4.44-2,6.44-7.09,6.44H29.42a2,2,0,0,1,0-4H48.33c1.88,0,3.09-.67,3.09-2.54V47.93a2,2,0,0,1,2-1.88A2.05,2.05,0,0,1,55.42,47.93Z' /%3E%3Cpath fill='%23fff' d='M80,40a1.84,1.84,0,0,1-.1.73,2.27,2.27,0,0,1-.42.62l-13.25,13A1.61,1.61,0,0,1,65,55a1.73,1.73,0,0,1-1.25-.52,1.83,1.83,0,0,1,0-2.61l10-9.85H31.3a2,2,0,0,1,0-4H73.74l-10-9.92a1.84,1.84,0,1,1,2.61-2.6L79.48,38.65a2.31,2.31,0,0,1,.42.63C79.9,39.49,80,39.8,80,40Z' /%3E%3C/svg%3E") }
.icon__logout--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='logoutRed' data-name='logoutRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Elogout_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M22.32.92a5.2,5.2,0,0,0-4.8-.53L5.11,5.4C1.46,6.75,0,8.73,0,12.38V67.63c0,3.65,1.46,5.63,5.11,7l12.51,5a6.75,6.75,0,0,0,2,.42c2.93,0,6.4-2.4,6.4-5.74V5.81C26,3.73,23.78,2,22.32.92ZM22,74.2a2,2,0,0,1-2,2,7.65,7.65,0,0,1-2-.28L6.25,71.18C4,70.15,4,69.72,4,67.63V12.38C4,10.3,4,9.67,6.36,8.84L17.8,4.58A6.71,6.71,0,0,1,20,4.15a2.08,2.08,0,0,1,2,2Z' /%3E%3Cpath fill='%23d42b1e' d='M18,36.15v8a2,2,0,0,1-1.88,2,2.18,2.18,0,0,1-2.1-2v-8a2.1,2.1,0,0,1,2-2A2.07,2.07,0,0,1,18,36.15Z' /%3E%3Cpath fill='%23d42b1e' d='M55.42,12.9V32.08a2,2,0,0,1-4,0V13a2.87,2.87,0,0,0-3.19-3H29.42a2.08,2.08,0,0,1-2-2,2.09,2.09,0,0,1,2-2H48.33C53.42,6.07,55.42,8.94,55.42,12.9Z' /%3E%3Cpath fill='%23d42b1e' d='M55.42,47.93v19.7c0,4.44-2,6.44-7.09,6.44H29.42a2,2,0,0,1,0-4H48.33c1.88,0,3.09-.67,3.09-2.54V47.93a2,2,0,0,1,2-1.88A2.05,2.05,0,0,1,55.42,47.93Z' /%3E%3Cpath fill='%23d42b1e' d='M80,40a1.84,1.84,0,0,1-.1.73,2.27,2.27,0,0,1-.42.62l-13.25,13A1.61,1.61,0,0,1,65,55a1.73,1.73,0,0,1-1.25-.52,1.83,1.83,0,0,1,0-2.61l10-9.85H31.3a2,2,0,0,1,0-4H73.74l-10-9.92a1.84,1.84,0,1,1,2.61-2.6L79.48,38.65a2.31,2.31,0,0,1,.42.63C79.9,39.49,80,39.8,80,40Z' /%3E%3C/svg%3E") }
.icon__notification { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='notification' data-name='notification' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Enotification%3C/title%3E%3Cpath fill='%23333' d='M48.92,4.14l-.16.16.17-.15Z' /%3E%3Cpath fill='%23333' d='M74,68.28c-.06-.56-1.52-11.84-9.51-15.89l.21-15.51c.16-11.34-6.7-21-16.22-24.26A8.85,8.85,0,0,0,49.2,9.1a9.27,9.27,0,0,0-18.53,0,9.06,9.06,0,0,0,.79,3.66C22.34,16.05,15.7,25.27,15.55,36.22l-.24,16.44C7.11,57.42,6.05,67.84,6,68.34a2.11,2.11,0,0,0,.55,1.6,2.15,2.15,0,0,0,1.57.67H27.75a12.6,12.6,0,0,0,24.37,0H71.88a2.11,2.11,0,0,0,1.58-.7A2,2,0,0,0,74,68.28ZM39.93,4.18a5,5,0,0,1,5,4.92,4.79,4.79,0,0,1-.74,2.54,23,23,0,0,0-2.36-.16l-2.75,0a22.89,22.89,0,0,0-3.43.24h0a4.82,4.82,0,0,1-.75-2.58A5,5,0,0,1,39.93,4.18ZM19.81,36.75C20,25.19,28.58,15.9,39,16.05l2.74,0c10.45.15,18.81,9.66,18.65,21.21l-.19,14.28v.48H19.6ZM39.93,75.82a8.32,8.32,0,0,1-7.73-5.21H47.66A8.29,8.29,0,0,1,39.93,75.82ZM10.67,66.05c.77-2.85,2.77-7.8,7.85-10H61.66c4.72,1.74,6.8,7,7.62,10Z' /%3E%3Cpath fill='%23333' d='M60.23,51.07v.47h-.29L60,51Z' /%3E%3C/svg%3E") }
.icon__notification--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='notificationWhite' data-name='notificationWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3ENotification_white%3C/title%3E%3Cpath fill='%23fff' d='M48.92,4.14l-.16.16.17-.15Z' /%3E%3Cpath fill='%23fff' d='M74,68.28c-.06-.56-1.52-11.84-9.51-15.89l.21-15.51c.16-11.34-6.7-21-16.22-24.26A8.85,8.85,0,0,0,49.2,9.1a9.27,9.27,0,0,0-18.53,0,9.06,9.06,0,0,0,.79,3.66C22.34,16.05,15.7,25.27,15.55,36.22l-.24,16.44C7.11,57.42,6.05,67.84,6,68.34a2.11,2.11,0,0,0,.55,1.6,2.15,2.15,0,0,0,1.57.67H27.75a12.6,12.6,0,0,0,24.37,0H71.88a2.11,2.11,0,0,0,1.58-.7A2,2,0,0,0,74,68.28ZM39.93,4.18a5,5,0,0,1,5,4.92,4.79,4.79,0,0,1-.74,2.54,23,23,0,0,0-2.36-.16l-2.75,0a22.89,22.89,0,0,0-3.43.24h0a4.82,4.82,0,0,1-.75-2.58A5,5,0,0,1,39.93,4.18ZM19.81,36.75C20,25.19,28.58,15.9,39,16.05l2.74,0c10.45.15,18.81,9.66,18.65,21.21l-.19,14.28v.48H19.6ZM39.93,75.82a8.32,8.32,0,0,1-7.73-5.21H47.66A8.29,8.29,0,0,1,39.93,75.82ZM10.67,66.05c.77-2.85,2.77-7.8,7.85-10H61.66c4.72,1.74,6.8,7,7.62,10Z' /%3E%3Cpath fill='%23fff' d='M60.23,51.07v.47h-.29L60,51Z' /%3E%3C/svg%3E") }
.icon__notification--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='notificationRed' data-name='notificationRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3ENotification_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M48.92,4.14l-.16.16.17-.15Z' /%3E%3Cpath fill='%23d42b1e' d='M74,68.28c-.06-.56-1.52-11.84-9.51-15.89l.21-15.51c.16-11.34-6.7-21-16.22-24.26A8.85,8.85,0,0,0,49.2,9.1a9.27,9.27,0,0,0-18.53,0,9.06,9.06,0,0,0,.79,3.66C22.34,16.05,15.7,25.27,15.55,36.22l-.24,16.44C7.11,57.42,6.05,67.84,6,68.34a2.11,2.11,0,0,0,.55,1.6,2.15,2.15,0,0,0,1.57.67H27.75a12.6,12.6,0,0,0,24.37,0H71.88a2.11,2.11,0,0,0,1.58-.7A2,2,0,0,0,74,68.28ZM39.93,4.18a5,5,0,0,1,5,4.92,4.79,4.79,0,0,1-.74,2.54,23,23,0,0,0-2.36-.16l-2.75,0a22.89,22.89,0,0,0-3.43.24h0a4.82,4.82,0,0,1-.75-2.58A5,5,0,0,1,39.93,4.18ZM19.81,36.75C20,25.19,28.58,15.9,39,16.05l2.74,0c10.45.15,18.81,9.66,18.65,21.21l-.19,14.28v.48H19.6ZM39.93,75.82a8.32,8.32,0,0,1-7.73-5.21H47.66A8.29,8.29,0,0,1,39.93,75.82ZM10.67,66.05c.77-2.85,2.77-7.8,7.85-10H61.66c4.72,1.74,6.8,7,7.62,10Z' /%3E%3Cpath fill='%23d42b1e' d='M60.23,51.07v.47h-.29L60,51Z' /%3E%3C/svg%3E") }
.icon__phone { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='phone' data-name='phone' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Ephone%3C/title%3E%3Cpath fill='%23333' d='M16.21,53.18A85.13,85.13,0,0,0,44.55,75.29c4.14,2,9.68,4.28,15.86,4.68.38,0,.75,0,1.13,0a13.16,13.16,0,0,0,10.18-4.36l.07-.08A41.51,41.51,0,0,1,75,72.24c.78-.74,1.58-1.52,2.35-2.32,3.54-3.68,3.54-8.35,0-11.92l-10-10a8.11,8.11,0,0,0-5.85-2.69A8.4,8.4,0,0,0,55.53,48l-6,5.94c-.54-.32-1.11-.6-1.64-.86s-1.28-.65-1.83-1A62.9,62.9,0,0,1,31,38.44,35.92,35.92,0,0,1,26,30.35c1.56-1.41,3-2.88,4.44-4.32.5-.52,1-1,1.53-1.55a8.46,8.46,0,0,0,2.76-6,8.43,8.43,0,0,0-2.76-6L27,7.61c-.58-.58-1.13-1.15-1.7-1.73C24.17,4.76,23,3.6,21.89,2.55A8.34,8.34,0,0,0,16,0,8.6,8.6,0,0,0,10.1,2.57L3.88,8.77A12.74,12.74,0,0,0,.07,16.92c-.32,4,.42,8.18,2.31,13.27C5.3,38.06,9.69,45.37,16.21,53.18ZM4.13,17.27a8.74,8.74,0,0,1,2.65-5.63L13,5.47A4.47,4.47,0,0,1,16,4.06,4.24,4.24,0,0,1,19,5.5c1.12,1,2.17,2.11,3.3,3.25.56.58,1.15,1.16,1.73,1.76l5,4.94a4.5,4.5,0,0,1,1.56,3.1A4.5,4.5,0,0,1,29,21.65c-.52.51-1,1-1.55,1.56-1.55,1.56-3,3-4.59,4.44l-.09.08A3.24,3.24,0,0,0,22,31.41a.78.78,0,0,1,.05.14A39,39,0,0,0,27.84,41a66.66,66.66,0,0,0,16,14.56c.72.46,1.48.82,2.2,1.19s1.28.65,1.83,1l.18.1a3.4,3.4,0,0,0,1.62.42,3.56,3.56,0,0,0,2.48-1.13l6.22-6.2a4.41,4.41,0,0,1,3.05-1.47,4.16,4.16,0,0,1,2.94,1.47l10,10c2,2,2,4.14-.05,6.25-.69.75-1.43,1.46-2.21,2.2A49.69,49.69,0,0,0,68.69,73a9.13,9.13,0,0,1-7.14,3l-.86,0c-5.46-.35-10.53-2.47-14.34-4.28a81,81,0,0,1-27-21.05C13.17,43.18,9,36.25,6.23,28.83,4.51,24.27,3.86,20.61,4.13,17.27Z' /%3E%3C/svg%3E") }
.icon__phone--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='phoneWhite' data-name='phoneWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Ephone_white%3C/title%3E%3Cpath fill='%23fff' d='M16.21,53.18A85.13,85.13,0,0,0,44.55,75.29c4.14,2,9.68,4.28,15.86,4.68.38,0,.75,0,1.13,0a13.16,13.16,0,0,0,10.18-4.36l.07-.08A41.51,41.51,0,0,1,75,72.24c.78-.74,1.58-1.52,2.35-2.32,3.54-3.68,3.54-8.35,0-11.92l-10-10a8.11,8.11,0,0,0-5.85-2.69A8.4,8.4,0,0,0,55.53,48l-6,5.94c-.54-.32-1.11-.6-1.64-.86s-1.28-.65-1.83-1A62.9,62.9,0,0,1,31,38.44,35.92,35.92,0,0,1,26,30.35c1.56-1.41,3-2.88,4.44-4.32.5-.52,1-1,1.53-1.55a8.46,8.46,0,0,0,2.76-6,8.43,8.43,0,0,0-2.76-6L27,7.61c-.58-.58-1.13-1.15-1.7-1.73C24.17,4.76,23,3.6,21.89,2.55A8.34,8.34,0,0,0,16,0,8.6,8.6,0,0,0,10.1,2.57L3.88,8.77A12.74,12.74,0,0,0,.07,16.92c-.32,4,.42,8.18,2.31,13.27C5.3,38.06,9.69,45.37,16.21,53.18ZM4.13,17.27a8.74,8.74,0,0,1,2.65-5.63L13,5.47A4.47,4.47,0,0,1,16,4.06,4.24,4.24,0,0,1,19,5.5c1.12,1,2.17,2.11,3.3,3.25.56.58,1.15,1.16,1.73,1.76l5,4.94a4.5,4.5,0,0,1,1.56,3.1A4.5,4.5,0,0,1,29,21.65c-.52.51-1,1-1.55,1.56-1.55,1.56-3,3-4.59,4.44l-.09.08A3.24,3.24,0,0,0,22,31.41a.78.78,0,0,1,.05.14A39,39,0,0,0,27.84,41a66.66,66.66,0,0,0,16,14.56c.72.46,1.48.82,2.2,1.19s1.28.65,1.83,1l.18.1a3.4,3.4,0,0,0,1.62.42,3.56,3.56,0,0,0,2.48-1.13l6.22-6.2a4.41,4.41,0,0,1,3.05-1.47,4.16,4.16,0,0,1,2.94,1.47l10,10c2,2,2,4.14-.05,6.25-.69.75-1.43,1.46-2.21,2.2A49.69,49.69,0,0,0,68.69,73a9.13,9.13,0,0,1-7.14,3l-.86,0c-5.46-.35-10.53-2.47-14.34-4.28a81,81,0,0,1-27-21.05C13.17,43.18,9,36.25,6.23,28.83,4.51,24.27,3.86,20.61,4.13,17.27Z' /%3E%3C/svg%3E") }
.icon__phone--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='phoneRed' data-name='phoneRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Ephone_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M16.21,53.18A85.13,85.13,0,0,0,44.55,75.29c4.14,2,9.68,4.28,15.86,4.68.38,0,.75,0,1.13,0a13.16,13.16,0,0,0,10.18-4.36l.07-.08A41.51,41.51,0,0,1,75,72.24c.78-.74,1.58-1.52,2.35-2.32,3.54-3.68,3.54-8.35,0-11.92l-10-10a8.11,8.11,0,0,0-5.85-2.69A8.4,8.4,0,0,0,55.53,48l-6,5.94c-.54-.32-1.11-.6-1.64-.86s-1.28-.65-1.83-1A62.9,62.9,0,0,1,31,38.44,35.92,35.92,0,0,1,26,30.35c1.56-1.41,3-2.88,4.44-4.32.5-.52,1-1,1.53-1.55a8.46,8.46,0,0,0,2.76-6,8.43,8.43,0,0,0-2.76-6L27,7.61c-.58-.58-1.13-1.15-1.7-1.73C24.17,4.76,23,3.6,21.89,2.55A8.34,8.34,0,0,0,16,0,8.6,8.6,0,0,0,10.1,2.57L3.88,8.77A12.74,12.74,0,0,0,.07,16.92c-.32,4,.42,8.18,2.31,13.27C5.3,38.06,9.69,45.37,16.21,53.18ZM4.13,17.27a8.74,8.74,0,0,1,2.65-5.63L13,5.47A4.47,4.47,0,0,1,16,4.06,4.24,4.24,0,0,1,19,5.5c1.12,1,2.17,2.11,3.3,3.25.56.58,1.15,1.16,1.73,1.76l5,4.94a4.5,4.5,0,0,1,1.56,3.1A4.5,4.5,0,0,1,29,21.65c-.52.51-1,1-1.55,1.56-1.55,1.56-3,3-4.59,4.44l-.09.08A3.24,3.24,0,0,0,22,31.41a.78.78,0,0,1,.05.14A39,39,0,0,0,27.84,41a66.66,66.66,0,0,0,16,14.56c.72.46,1.48.82,2.2,1.19s1.28.65,1.83,1l.18.1a3.4,3.4,0,0,0,1.62.42,3.56,3.56,0,0,0,2.48-1.13l6.22-6.2a4.41,4.41,0,0,1,3.05-1.47,4.16,4.16,0,0,1,2.94,1.47l10,10c2,2,2,4.14-.05,6.25-.69.75-1.43,1.46-2.21,2.2A49.69,49.69,0,0,0,68.69,73a9.13,9.13,0,0,1-7.14,3l-.86,0c-5.46-.35-10.53-2.47-14.34-4.28a81,81,0,0,1-27-21.05C13.17,43.18,9,36.25,6.23,28.83,4.51,24.27,3.86,20.61,4.13,17.27Z' /%3E%3C/svg%3E") }
.icon__post { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='post' data-name='post' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Epost%3C/title%3E%3Cpath fill='%23333' d='M58.44,24H54V18s7.38-6.36,9-8S64.54,7,63,6s-9-5.6-9-5.6c-2.08-1.09-4,.42-4,2.77V24H21.56A21.4,21.4,0,0,0,0,45.31V76.88A3.12,3.12,0,0,0,3.12,80H76.87A3.13,3.13,0,0,0,80,76.88V45.31A21.41,21.41,0,0,0,58.44,24ZM54,4l6,4-6,6ZM4,45.31C4,36.87,13.12,28,21.56,28S38,36.87,38,45.31V76H4ZM76,76H42V45.31c0-6-3.17-13.6-9-17.35H50V41c0,1.73.36,3.13,2.09,3.13S54,42.7,54,41V28h4.45C66.88,28,76,36.87,76,45.31Z' /%3E%3C/svg%3E") }
.icon__post--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='postWhite' data-name='postWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Epost_white%3C/title%3E%3Cpath fill='%23fff' d='M58.44,24H54V18s7.38-6.36,9-8S64.54,7,63,6s-9-5.6-9-5.6c-2.08-1.09-4,.42-4,2.77V24H21.56A21.4,21.4,0,0,0,0,45.31V76.88A3.12,3.12,0,0,0,3.12,80H76.87A3.13,3.13,0,0,0,80,76.88V45.31A21.41,21.41,0,0,0,58.44,24ZM54,4l6,4-6,6ZM4,45.31C4,36.87,13.12,28,21.56,28S38,36.87,38,45.31V76H4ZM76,76H42V45.31c0-6-3.17-13.6-9-17.35H50V41c0,1.73.36,3.13,2.09,3.13S54,42.7,54,41V28h4.45C66.88,28,76,36.87,76,45.31Z' /%3E%3C/svg%3E") }
.icon__post--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='postRed' data-name='postRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Epost_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M58.44,24H54V18s7.38-6.36,9-8S64.54,7,63,6s-9-5.6-9-5.6c-2.08-1.09-4,.42-4,2.77V24H21.56A21.4,21.4,0,0,0,0,45.31V76.88A3.12,3.12,0,0,0,3.12,80H76.87A3.13,3.13,0,0,0,80,76.88V45.31A21.41,21.41,0,0,0,58.44,24ZM54,4l6,4-6,6ZM4,45.31C4,36.87,13.12,28,21.56,28S38,36.87,38,45.31V76H4ZM76,76H42V45.31c0-6-3.17-13.6-9-17.35H50V41c0,1.73.36,3.13,2.09,3.13S54,42.7,54,41V28h4.45C66.88,28,76,36.87,76,45.31Z' /%3E%3C/svg%3E") }
.icon__repeat { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='repeat' data-name='repeat' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Erepeat%3C/title%3E%3Cpath fill='%23333' d='M39.32,80a34.58,34.58,0,0,1-17.89-5A33.9,33.9,0,0,1,5.9,53.92,2.16,2.16,0,0,1,7.51,51.3a2.2,2.2,0,0,1,2.65,1.6A30.1,30.1,0,0,0,46.45,74.81,29.76,29.76,0,0,0,68.6,38.9,30.11,30.11,0,0,0,32.31,17a2.17,2.17,0,1,1-1-4.22A34.51,34.51,0,0,1,72.86,37.88,34.1,34.1,0,0,1,47.48,79,35,35,0,0,1,39.32,80Z' /%3E%3Cpath fill='%23333' d='M41.56,31.59A2.17,2.17,0,0,1,40,30.94L24.86,15.69,40.16.63a2.21,2.21,0,0,1,3.1,0,2.16,2.16,0,0,1,0,3.07L31,15.73,43.13,27.9a2.17,2.17,0,0,1-1.57,3.69Z' /%3E%3C/svg%3E") }
.icon__repeat--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='repeatWhite' data-name='repeatWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Erepeat_white%3C/title%3E%3Cpath fill='%23fff' d='M39.32,80a34.58,34.58,0,0,1-17.89-5A33.9,33.9,0,0,1,5.9,53.92,2.16,2.16,0,0,1,7.51,51.3a2.2,2.2,0,0,1,2.65,1.6A30.1,30.1,0,0,0,46.45,74.81,29.76,29.76,0,0,0,68.6,38.9,30.11,30.11,0,0,0,32.31,17a2.17,2.17,0,1,1-1-4.22A34.51,34.51,0,0,1,72.86,37.88,34.1,34.1,0,0,1,47.48,79,35,35,0,0,1,39.32,80Z' /%3E%3Cpath fill='%23fff' d='M41.56,31.59A2.17,2.17,0,0,1,40,30.94L24.86,15.69,40.16.63a2.21,2.21,0,0,1,3.1,0,2.16,2.16,0,0,1,0,3.07L31,15.73,43.13,27.9a2.17,2.17,0,0,1-1.57,3.69Z' /%3E%3C/svg%3E") }
.icon__repeat--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='repeatRed' data-name='repeatRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Erepeat_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M39.32,80a34.58,34.58,0,0,1-17.89-5A33.9,33.9,0,0,1,5.9,53.92,2.16,2.16,0,0,1,7.51,51.3a2.2,2.2,0,0,1,2.65,1.6A30.1,30.1,0,0,0,46.45,74.81,29.76,29.76,0,0,0,68.6,38.9,30.11,30.11,0,0,0,32.31,17a2.17,2.17,0,1,1-1-4.22A34.51,34.51,0,0,1,72.86,37.88,34.1,34.1,0,0,1,47.48,79,35,35,0,0,1,39.32,80Z' /%3E%3Cpath fill='%23d42b1e' d='M41.56,31.59A2.17,2.17,0,0,1,40,30.94L24.86,15.69,40.16.63a2.21,2.21,0,0,1,3.1,0,2.16,2.16,0,0,1,0,3.07L31,15.73,43.13,27.9a2.17,2.17,0,0,1-1.57,3.69Z' /%3E%3C/svg%3E") }
.icon__search { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='search' data-name='search' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Esearch%3C/title%3E%3Cpath fill='%23333' d='M31.3,3.9A27.4,27.4,0,1,1,3.9,31.3,27.43,27.43,0,0,1,31.3,3.9m0-3.9A31.3,31.3,0,1,0,62.59,31.3,31.3,31.3,0,0,0,31.3,0Z' /%3E%3Cpath fill='%23333' d='M78,80a2,2,0,0,1-1.45-.6L51.6,54.49a2,2,0,0,1,2.89-2.89L79.4,76.51A2,2,0,0,1,78,80Z' /%3E%3C/svg%3E") }
.icon__search--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='searchWhite' data-name='searchWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Esearch_white%3C/title%3E%3Cpath fill='%23fff' d='M31.3,3.9A27.4,27.4,0,1,1,3.9,31.3,27.43,27.43,0,0,1,31.3,3.9m0-3.9A31.3,31.3,0,1,0,62.59,31.3,31.3,31.3,0,0,0,31.3,0Z' /%3E%3Cpath fill='%23fff' d='M78,80a2,2,0,0,1-1.45-.6L51.6,54.49a2,2,0,0,1,2.89-2.89L79.4,76.51A2,2,0,0,1,78,80Z' /%3E%3C/svg%3E") }
.icon__search--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='searchRed' data-name='searchRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Esearch_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M31.3,3.9A27.4,27.4,0,1,1,3.9,31.3,27.43,27.43,0,0,1,31.3,3.9m0-3.9A31.3,31.3,0,1,0,62.59,31.3,31.3,31.3,0,0,0,31.3,0Z' /%3E%3Cpath fill='%23d42b1e' d='M78,80a2,2,0,0,1-1.45-.6L51.6,54.49a2,2,0,0,1,2.89-2.89L79.4,76.51A2,2,0,0,1,78,80Z' /%3E%3C/svg%3E") }
.icon__see { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='see' data-name='see' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Esee%3C/title%3E%3Cpath fill='%23333' d='M40,28A12,12,0,1,1,28,40,12,12,0,0,1,40,28m0-4A16,16,0,1,0,56,40,16,16,0,0,0,40,24Z' /%3E%3Cpath fill='%23333' d='M39.77,18v4h.45C58.9,22,71,37.48,75,43.52A50.49,50.49,0,0,1,40,58,50.62,50.62,0,0,1,5,43.57C9,37.51,21.09,22,39.77,22V18m.45,0h-.46C13.91,18,0,44.05,0,44.05a53.49,53.49,0,0,0,80,0S66.08,18,40.22,18Z' /%3E%3Cpath fill='%23333' d='M33.84,41.85a2,2,0,0,1-2-2A8.17,8.17,0,0,1,40,31.69a2,2,0,1,1,0,4,4.16,4.16,0,0,0-4.15,4.16A2,2,0,0,1,33.84,41.85Z' /%3E%3C/svg%3E") }
.icon__see--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='seeWhite' data-name='seeWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Esee_white%3C/title%3E%3Cpath fill='%23fff' d='M40,28A12,12,0,1,1,28,40,12,12,0,0,1,40,28m0-4A16,16,0,1,0,56,40,16,16,0,0,0,40,24Z' /%3E%3Cpath fill='%23fff' d='M39.77,18v4h.45C58.9,22,71,37.48,75,43.52A50.49,50.49,0,0,1,40,58,50.62,50.62,0,0,1,5,43.57C9,37.51,21.09,22,39.77,22V18m.45,0h-.46C13.91,18,0,44.05,0,44.05a53.49,53.49,0,0,0,80,0S66.08,18,40.22,18Z' /%3E%3Cpath fill='%23fff' d='M33.84,41.85a2,2,0,0,1-2-2A8.17,8.17,0,0,1,40,31.69a2,2,0,1,1,0,4,4.16,4.16,0,0,0-4.15,4.16A2,2,0,0,1,33.84,41.85Z' /%3E%3C/svg%3E") }
.icon__see--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='seeRed' data-name='seeRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Esee_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M40,28A12,12,0,1,1,28,40,12,12,0,0,1,40,28m0-4A16,16,0,1,0,56,40,16,16,0,0,0,40,24Z' /%3E%3Cpath fill='%23d42b1e' d='M39.77,18v4h.45C58.9,22,71,37.48,75,43.52A50.49,50.49,0,0,1,40,58,50.62,50.62,0,0,1,5,43.57C9,37.51,21.09,22,39.77,22V18m.45,0h-.46C13.91,18,0,44.05,0,44.05a53.49,53.49,0,0,0,80,0S66.08,18,40.22,18Z' /%3E%3Cpath fill='%23d42b1e' d='M33.84,41.85a2,2,0,0,1-2-2A8.17,8.17,0,0,1,40,31.69a2,2,0,1,1,0,4,4.16,4.16,0,0,0-4.15,4.16A2,2,0,0,1,33.84,41.85Z' /%3E%3C/svg%3E") }
.icon__trash { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='trash' data-name='trash' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Edelete%3C/title%3E%3Cpath fill='%23333' d='M56.79,80l-33.2,0c-3.31,0-5.83-.79-7.46-2.4a6.94,6.94,0,0,1-2-5.08V20.15A2.13,2.13,0,0,1,16.27,18H64.18a2.13,2.13,0,0,1,2.13,2.11V72.49C66.31,75.92,64.66,80,56.79,80ZM23.58,75.75l33.22,0c5.24,0,5.24-2,5.24-3.29V22.26H18.41V72.49a2.76,2.76,0,0,0,.73,2.09C19.93,75.36,21.45,75.75,23.58,75.75Z' /%3E%3Cpath fill='%23333' d='M40.28,70a2.12,2.12,0,0,1-2.13-2.11V28.15a2.14,2.14,0,0,1,4.27,0V67.93A2.12,2.12,0,0,1,40.28,70Z' /%3E%3Cpath fill='%23333' d='M28.28,70a2.11,2.11,0,0,1-2.13-2.11V28.15a2.13,2.13,0,0,1,4.26,0V67.93A2.11,2.11,0,0,1,28.28,70Z' /%3E%3Cpath fill='%23333' d='M52,70a2.12,2.12,0,0,1-2.14-2.11V28.15a2.14,2.14,0,0,1,4.27,0V67.93A2.12,2.12,0,0,1,52,70Z' /%3E%3Cpath fill='%23333' d='M70,16H10.28A2.13,2.13,0,0,1,8.15,13.9V6.4a2.13,2.13,0,0,1,2.13-2.14h14L28.16.59A2.1,2.1,0,0,1,29.62,0H51a2.15,2.15,0,0,1,1.44.56l4,3.7H70A2.14,2.14,0,0,1,72.15,6.4v7.5A2.14,2.14,0,0,1,70,16ZM12.41,11.75H67.88V8.55H55.56A2.1,2.1,0,0,1,54.12,8l-4-3.69H30.47L26.61,8a2.12,2.12,0,0,1-1.46.59H12.41Z' /%3E%3C/svg%3E") }
.icon__trash--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='trashWhite' data-name='trashWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Edelete_white%3C/title%3E%3Cpath fill='%23fff' d='M56.79,80l-33.2,0c-3.31,0-5.83-.79-7.46-2.4a6.94,6.94,0,0,1-2-5.08V20.15A2.13,2.13,0,0,1,16.27,18H64.18a2.13,2.13,0,0,1,2.13,2.11V72.49C66.31,75.92,64.66,80,56.79,80ZM23.58,75.75l33.22,0c5.24,0,5.24-2,5.24-3.29V22.26H18.41V72.49a2.76,2.76,0,0,0,.73,2.09C19.93,75.36,21.45,75.75,23.58,75.75Z' /%3E%3Cpath fill='%23fff' d='M40.28,70a2.12,2.12,0,0,1-2.13-2.11V28.15a2.14,2.14,0,0,1,4.27,0V67.93A2.12,2.12,0,0,1,40.28,70Z' /%3E%3Cpath fill='%23fff' d='M28.28,70a2.11,2.11,0,0,1-2.13-2.11V28.15a2.13,2.13,0,0,1,4.26,0V67.93A2.11,2.11,0,0,1,28.28,70Z' /%3E%3Cpath fill='%23fff' d='M52,70a2.12,2.12,0,0,1-2.14-2.11V28.15a2.14,2.14,0,0,1,4.27,0V67.93A2.12,2.12,0,0,1,52,70Z' /%3E%3Cpath fill='%23fff' d='M70,16H10.28A2.13,2.13,0,0,1,8.15,13.9V6.4a2.13,2.13,0,0,1,2.13-2.14h14L28.16.59A2.1,2.1,0,0,1,29.62,0H51a2.15,2.15,0,0,1,1.44.56l4,3.7H70A2.14,2.14,0,0,1,72.15,6.4v7.5A2.14,2.14,0,0,1,70,16ZM12.41,11.75H67.88V8.55H55.56A2.1,2.1,0,0,1,54.12,8l-4-3.69H30.47L26.61,8a2.12,2.12,0,0,1-1.46.59H12.41Z' /%3E%3C/svg%3E") }
.icon__trash--red {background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='trashRed' data-name='trashRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Edelete_white%3C/title%3E%3Cpath fill='%23d42b1e' d='M56.79,80l-33.2,0c-3.31,0-5.83-.79-7.46-2.4a6.94,6.94,0,0,1-2-5.08V20.15A2.13,2.13,0,0,1,16.27,18H64.18a2.13,2.13,0,0,1,2.13,2.11V72.49C66.31,75.92,64.66,80,56.79,80ZM23.58,75.75l33.22,0c5.24,0,5.24-2,5.24-3.29V22.26H18.41V72.49a2.76,2.76,0,0,0,.73,2.09C19.93,75.36,21.45,75.75,23.58,75.75Z' /%3E%3Cpath fill='%23d42b1e' d='M40.28,70a2.12,2.12,0,0,1-2.13-2.11V28.15a2.14,2.14,0,0,1,4.27,0V67.93A2.12,2.12,0,0,1,40.28,70Z' /%3E%3Cpath fill='%23d42b1e' d='M28.28,70a2.11,2.11,0,0,1-2.13-2.11V28.15a2.13,2.13,0,0,1,4.26,0V67.93A2.11,2.11,0,0,1,28.28,70Z' /%3E%3Cpath fill='%23d42b1e' d='M52,70a2.12,2.12,0,0,1-2.14-2.11V28.15a2.14,2.14,0,0,1,4.27,0V67.93A2.12,2.12,0,0,1,52,70Z' /%3E%3Cpath fill='%23d42b1e' d='M70,16H10.28A2.13,2.13,0,0,1,8.15,13.9V6.4a2.13,2.13,0,0,1,2.13-2.14h14L28.16.59A2.1,2.1,0,0,1,29.62,0H51a2.15,2.15,0,0,1,1.44.56l4,3.7H70A2.14,2.14,0,0,1,72.15,6.4v7.5A2.14,2.14,0,0,1,70,16ZM12.41,11.75H67.88V8.55H55.56A2.1,2.1,0,0,1,54.12,8l-4-3.69H30.47L26.61,8a2.12,2.12,0,0,1-1.46.59H12.41Z' /%3E%3C/svg%3E") }
.icon__burgerMenu { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='burgerMenu' data-name='burgerMenu' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_burger_menu%3C/title%3E%3Cpath fill='%23333' d='M78,14.26H1.9a2,2,0,0,1,0-4H78a2,2,0,0,1,0,4Z'/%3E%3Cpath fill='%23333' d='M78,70.26H2a2,2,0,0,1,0-4H78a2,2,0,0,1,0,4Z'/%3E%3Cpath fill='%23333' d='M77.9,42.26H2a2,2,0,0,1,0-4H77.9a2,2,0,0,1,0,4Z'/%3E%3C/svg%3E") }
.icon__burgerMenu--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='burgerMenuWhite' data-name='burgerMenuWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_burger_menu%3C/title%3E%3Cpath fill='%23fff' d='M78,14.26H1.9a2,2,0,0,1,0-4H78a2,2,0,0,1,0,4Z'/%3E%3Cpath fill='%23fff' d='M78,70.26H2a2,2,0,0,1,0-4H78a2,2,0,0,1,0,4Z'/%3E%3Cpath fill='%23fff' d='M77.9,42.26H2a2,2,0,0,1,0-4H77.9a2,2,0,0,1,0,4Z'/%3E%3C/svg%3E") }
.icon__burgerMenu--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='burgerMenuRed' data-name='burgerMenuRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_burger_menu%3C/title%3E%3Cpath fill='%23d42b1e' d='M78,14.26H1.9a2,2,0,0,1,0-4H78a2,2,0,0,1,0,4Z'/%3E%3Cpath fill='%23d42b1e' d='M78,70.26H2a2,2,0,0,1,0-4H78a2,2,0,0,1,0,4Z'/%3E%3Cpath fill='%23d42b1e' d='M77.9,42.26H2a2,2,0,0,1,0-4H77.9a2,2,0,0,1,0,4Z'/%3E%3C/svg%3E") }
.icon__chevron { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='chevron' data-name='chevron' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_chewron%3C/title%3E%3Cpath fill='%23333' d='M40,62a4,4,0,0,1-2.75-1.09l-36-34a4,4,0,1,1,5.5-5.82L40,52.5,73.25,21.09a4,4,0,0,1,5.5,5.82l-36,34A4,4,0,0,1,40,62Z'/%3E%3C/svg%3E") }
.icon__chevron--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='chevronWhite' data-name='chevronWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_chewron%3C/title%3E%3Cpath fill='%23fff' d='M40,62a4,4,0,0,1-2.75-1.09l-36-34a4,4,0,1,1,5.5-5.82L40,52.5,73.25,21.09a4,4,0,0,1,5.5,5.82l-36,34A4,4,0,0,1,40,62Z'/%3E%3C/svg%3E") }
.icon__chevron--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='chevronRed' data-name='chevronRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_chewron%3C/title%3E%3Cpath fill='%23d42b1e' d='M40,62a4,4,0,0,1-2.75-1.09l-36-34a4,4,0,1,1,5.5-5.82L40,52.5,73.25,21.09a4,4,0,0,1,5.5,5.82l-36,34A4,4,0,0,1,40,62Z'/%3E%3C/svg%3E") }
.icon__email { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='email' data-name='email' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_e-post%3C/title%3E%3Cpath fill='%23333' d='M73,14H7a7.24,7.24,0,0,0-7,6.7V59.34C0,62.52,3.15,66,7,66H73c3.86,0,7-3.42,7-6.62V20.66A7.23,7.23,0,0,0,73,14Zm-1,4L40.15,45,8,18ZM4.05,60V20L27.26,39.91Zm4,2L30.59,42.63l7.92,6.48a2.7,2.7,0,0,0,3.3,0l7.72-6.37L72,62Zm68.05-2L52.85,40l23.2-20Z'/%3E%3C/svg%3E") }
.icon__email--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='emailWhite' data-name='emailWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_e-post%3C/title%3E%3Cpath fill='%23fff' d='M73,14H7a7.24,7.24,0,0,0-7,6.7V59.34C0,62.52,3.15,66,7,66H73c3.86,0,7-3.42,7-6.62V20.66A7.23,7.23,0,0,0,73,14Zm-1,4L40.15,45,8,18ZM4.05,60V20L27.26,39.91Zm4,2L30.59,42.63l7.92,6.48a2.7,2.7,0,0,0,3.3,0l7.72-6.37L72,62Zm68.05-2L52.85,40l23.2-20Z'/%3E%3C/svg%3E") }
.icon__email--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='emailRed' data-name='emailRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_e-post%3C/title%3E%3Cpath fill='%23d42b1e' d='M73,14H7a7.24,7.24,0,0,0-7,6.7V59.34C0,62.52,3.15,66,7,66H73c3.86,0,7-3.42,7-6.62V20.66A7.23,7.23,0,0,0,73,14Zm-1,4L40.15,45,8,18ZM4.05,60V20L27.26,39.91Zm4,2L30.59,42.63l7.92,6.48a2.7,2.7,0,0,0,3.3,0l7.72-6.37L72,62Zm68.05-2L52.85,40l23.2-20Z'/%3E%3C/svg%3E") }
.icon__plus { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='plus' data-name='plus' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_plus%3C/title%3E%3Cpath fill='%23333' d='M40,80a4,4,0,0,1-4-4V4a4,4,0,0,1,8,0V76A4,4,0,0,1,40,80Z'/%3E%3Cpath fill='%23333' d='M76,44H4a4,4,0,0,1,0-8H76a4,4,0,0,1,0,8Z'/%3E%3C/svg%3E") }
.icon__plus--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='plusWhite' data-name='plusWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_plus%3C/title%3E%3Cpath fill='%23fff' d='M40,80a4,4,0,0,1-4-4V4a4,4,0,0,1,8,0V76A4,4,0,0,1,40,80Z'/%3E%3Cpath fill='%23fff' d='M76,44H4a4,4,0,0,1,0-8H76a4,4,0,0,1,0,8Z'/%3E%3C/svg%3E") }
.icon__plus--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='plusRed' data-name='plusRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_plus%3C/title%3E%3Cpath fill='%23d42b1e' d='M40,80a4,4,0,0,1-4-4V4a4,4,0,0,1,8,0V76A4,4,0,0,1,40,80Z'/%3E%3Cpath fill='%23d42b1e' d='M76,44H4a4,4,0,0,1,0-8H76a4,4,0,0,1,0,8Z'/%3E%3C/svg%3E") }
.icon__secondaryMenu { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='secondaryMenu' data-name='secondaryMenu' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_sec_menu%3C/title%3E%3Cpath fill='%23333' d='M40,4a5.63,5.63,0,0,1,4.19,1.75,5.77,5.77,0,0,1,1.75,4.31,5.73,5.73,0,0,1-1.75,4.3,5.87,5.87,0,0,1-8.38,0,5.69,5.69,0,0,1-1.75-4.3,5.77,5.77,0,0,1,1.75-4.31A5.63,5.63,0,0,1,40,4m0,29.94a5.63,5.63,0,0,1,4.19,1.75,6.18,6.18,0,0,1,0,8.62,5.88,5.88,0,0,1-8.38,0A5.69,5.69,0,0,1,34.06,40a5.77,5.77,0,0,1,1.75-4.31A5.63,5.63,0,0,1,40,33.94m0,29.94a5.63,5.63,0,0,1,4.19,1.75,6.18,6.18,0,0,1,0,8.62,5.89,5.89,0,0,1-8.38,0,6.17,6.17,0,0,1,0-8.61A5.6,5.6,0,0,1,40,63.88M40,0a9.61,9.61,0,0,0-7,2.92,9.73,9.73,0,0,0-2.92,7.14A9.69,9.69,0,0,0,33,17.19a9.87,9.87,0,0,0,14,0,9.69,9.69,0,0,0,2.92-7.13A9.73,9.73,0,0,0,47,2.92,9.61,9.61,0,0,0,40,0Zm0,29.94a9.58,9.58,0,0,0-7,2.93,10.16,10.16,0,0,0,0,14.26,9.87,9.87,0,0,0,14,0,10.16,10.16,0,0,0,0-14.26,9.58,9.58,0,0,0-7-2.93Zm0,29.94a9.58,9.58,0,0,0-7,2.93,10.18,10.18,0,0,0,0,14.27,9.9,9.9,0,0,0,14,0,10.18,10.18,0,0,0,0-14.27,9.58,9.58,0,0,0-7-2.93Z'/%3E%3C/svg%3E") }
.icon__secondaryMenu--white { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='secondaryMenuWhite' data-name='secondaryMenuWhite' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_sec_menu%3C/title%3E%3Cpath fill='%23fff' d='M40,4a5.63,5.63,0,0,1,4.19,1.75,5.77,5.77,0,0,1,1.75,4.31,5.73,5.73,0,0,1-1.75,4.3,5.87,5.87,0,0,1-8.38,0,5.69,5.69,0,0,1-1.75-4.3,5.77,5.77,0,0,1,1.75-4.31A5.63,5.63,0,0,1,40,4m0,29.94a5.63,5.63,0,0,1,4.19,1.75,6.18,6.18,0,0,1,0,8.62,5.88,5.88,0,0,1-8.38,0A5.69,5.69,0,0,1,34.06,40a5.77,5.77,0,0,1,1.75-4.31A5.63,5.63,0,0,1,40,33.94m0,29.94a5.63,5.63,0,0,1,4.19,1.75,6.18,6.18,0,0,1,0,8.62,5.89,5.89,0,0,1-8.38,0,6.17,6.17,0,0,1,0-8.61A5.6,5.6,0,0,1,40,63.88M40,0a9.61,9.61,0,0,0-7,2.92,9.73,9.73,0,0,0-2.92,7.14A9.69,9.69,0,0,0,33,17.19a9.87,9.87,0,0,0,14,0,9.69,9.69,0,0,0,2.92-7.13A9.73,9.73,0,0,0,47,2.92,9.61,9.61,0,0,0,40,0Zm0,29.94a9.58,9.58,0,0,0-7,2.93,10.16,10.16,0,0,0,0,14.26,9.87,9.87,0,0,0,14,0,10.16,10.16,0,0,0,0-14.26,9.58,9.58,0,0,0-7-2.93Zm0,29.94a9.58,9.58,0,0,0-7,2.93,10.18,10.18,0,0,0,0,14.27,9.9,9.9,0,0,0,14,0,10.18,10.18,0,0,0,0-14.27,9.58,9.58,0,0,0-7-2.93Z'/%3E%3C/svg%3E") }
.icon__secondaryMenu--red { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='secondaryMenuRed' data-name='secondaryMenuRed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Ctitle%3Eic_sec_menu%3C/title%3E%3Cpath fill='%23d42b1e' d='M40,4a5.63,5.63,0,0,1,4.19,1.75,5.77,5.77,0,0,1,1.75,4.31,5.73,5.73,0,0,1-1.75,4.3,5.87,5.87,0,0,1-8.38,0,5.69,5.69,0,0,1-1.75-4.3,5.77,5.77,0,0,1,1.75-4.31A5.63,5.63,0,0,1,40,4m0,29.94a5.63,5.63,0,0,1,4.19,1.75,6.18,6.18,0,0,1,0,8.62,5.88,5.88,0,0,1-8.38,0A5.69,5.69,0,0,1,34.06,40a5.77,5.77,0,0,1,1.75-4.31A5.63,5.63,0,0,1,40,33.94m0,29.94a5.63,5.63,0,0,1,4.19,1.75,6.18,6.18,0,0,1,0,8.62,5.89,5.89,0,0,1-8.38,0,6.17,6.17,0,0,1,0-8.61A5.6,5.6,0,0,1,40,63.88M40,0a9.61,9.61,0,0,0-7,2.92,9.73,9.73,0,0,0-2.92,7.14A9.69,9.69,0,0,0,33,17.19a9.87,9.87,0,0,0,14,0,9.69,9.69,0,0,0,2.92-7.13A9.73,9.73,0,0,0,47,2.92,9.61,9.61,0,0,0,40,0Zm0,29.94a9.58,9.58,0,0,0-7,2.93,10.16,10.16,0,0,0,0,14.26,9.87,9.87,0,0,0,14,0,10.16,10.16,0,0,0,0-14.26,9.58,9.58,0,0,0-7-2.93Zm0,29.94a9.58,9.58,0,0,0-7,2.93,10.18,10.18,0,0,0,0,14.27,9.9,9.9,0,0,0,14,0,10.18,10.18,0,0,0,0-14.27,9.58,9.58,0,0,0-7-2.93Z'/%3E%3C/svg%3E") }
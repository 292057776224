.header {
  align-items: center;
  justify-content: space-between;
  min-height: 78px;
  width: 100%;
  padding: 5px 0 10px;
  background-color: white;
  position: fixed;
  -webkit-box-shadow: 0px -8px 39px 8px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px -8px 39px 8px rgba(0,0,0,0.2);
  box-shadow: 0px -8px 39px 8px rgba(0,0,0,0.2);
}
/* Target ForkMeOnGithub */
.header > a > svg {
  width: 40px;
  height: 40px;
}
.header__items {
  justify-content: space-between;
  align-items: center;
}
.logo {
  margin-left: 25px;
}
.heading--site {
  margin-bottom: 5px;
}
.message {
  max-width: 420px;
}
.heading--message {
  margin: 5px 0 0;
}
.options {
  margin-right: 10px;
  justify-content: space-between;
  flex-direction: row;
}
.option {
  display: flex;
  align-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #efefef;
}
.option.is-active {
  background-color: #555;
}
.header .option--search {
  margin-right: 10px;
}
.legends {
  width: 140px;
  justify-content: space-between;
  margin: 0 auto;
}
.legend {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 46px;
}
.legend__color,
.legend__label {
  display: inline-block;
}
.legend__color {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-bottom: 5px;
}
.legend__label {
  font-size: 14px;
}

/* RESPONSIVE */

@media (max-width: 320px) {
  .header__items {
    flex-direction: column;
  }
  .logo {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .options {
    margin-right: 0;
  }
}
@media (max-width: 740px) {
  .message {
    max-width: 300px;
  }
}
@media (max-width: 620px) {
  .message {
    max-width: 200px;
  }
}
@media (max-width: 530px) {
  .message {
    max-width: 150px;
  }
  .message h2 {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .message {
    display: none !important;
  }
}